import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { PaginatedUsers, User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private apiUsers = 'api/users';
  private apiCards = 'api/cards';

  constructor(private http: HttpClient) {}

  getUsers(params: HttpParams): Observable<PaginatedUsers> {
    const url = `${this.apiUsers}/`;
    const request: Observable<any> = this.http.get(url, { params });
    return request.pipe(
      map((res: PaginatedUsers) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  getOnlyMembers(params: HttpParams): Observable<PaginatedUsers> {
    const url = `${this.apiUsers}/get-members`;
    const request: Observable<any> = this.http.get(url, { params });
    return request.pipe(
      map((res: PaginatedUsers) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  getUser(id: string): Observable<User> {
    const url = `${this.apiUsers}/${id}`;
    const params = new HttpParams().append('preloadCard', 'true').append('preloadHistory', 'true');
    const request: Observable<any> = this.http.get(url, { params });
    return request.pipe(
      map((res: User) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  setUser(create: boolean, user: User): Observable<any> {
    const request: Observable<any> = create
      ? this.http.post(`${this.apiUsers}/`, user)
      : this.http.put(`${this.apiUsers}/`, user);
    const log: string = create ? 'POST ' : 'PUT ' + this.apiUsers;
    return request.pipe(
      map((res: any) => {
        console.log(log, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error(log, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  setOnlyMember(user: User): Observable<any> {
    const log: string = 'POST ' + this.apiUsers;
    const params = new HttpParams().append('isOnlyMember', 'true');
    const request: Observable<any> = this.http.post(`${this.apiUsers}/`, user, { params });
    
    return request.pipe(
      map((res: any) => {
        console.log(log, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error(log, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  getUserPhoto(id: string): Observable<any> {
    const url = `${this.apiUsers}/${id}/get-photo`;
    const request: Observable<any> = this.http.get(url, { responseType: 'blob' });
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  posttUserPhoto(id: string, data: FormData): Observable<any> {
    const url = `${this.apiUsers}/${id}/put-photo`;
    const request: Observable<any> = this.http.put(url, data);
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  removetUserPhoto(id: string): Observable<any> {
    const url = `${this.apiUsers}/${id}/remove-photo`;
    const request: Observable<any> = this.http.put(url, {});
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  actionsUser(action: string, id: string, card?: string): Observable<any> {
    const url = card
      ? `${this.apiUsers}/${id}/${action}/${card}`
      : `${this.apiUsers}/${id}/${action}`;
    const request: Observable<any> = this.http.put(url, {});
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  getUserEntrances(id: string): Observable<any> {
    const url = `${this.apiUsers}/${id}/entrances`;
    const request: Observable<any> = this.http.get(url);
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  putUserEntrance(tag: string): Observable<any> {
    const url = `${this.apiCards}/${tag}/entrance`;
    const request: Observable<any> = this.http.put(url, {});
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  putUserEntranceWithoutCard(userId: string): Observable<any> {
    const url = `${this.apiCards}/without/entrance/${userId}`;
    const request: Observable<any> = this.http.put(url, {});
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }

  getCard(id: string): Observable<any> {
    const url = `${this.apiCards}/${id}`;
    const request: Observable<any> = this.http.get(url);
    return request.pipe(
      map((res: any) => {
        console.log('GET ' + url, res);
        return res;
      }),
      catchError((errorRes: any) => {
        console.error('GET ' + url, errorRes);
        return throwError(errorRes);
      }),
    );
  }
}
