import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { ToasterConfig, ToasterService } from 'angular2-toaster';

import { cilEnvelopeOpen, flagSet } from '@coreui/icons';
import { IconSetService } from '@coreui/icons-angular';

import { NfcMessage, WebsocketService } from './shared/services/websocket.service';
import { ApiService } from './shared/services/api.service';

import cameraretake from '@iconify/icons-mdi/camera-retake';
import cameraoff from '@iconify/icons-mdi/camera-off';
import cameraplus from '@iconify/icons-mdi/camera-plus';
import camera from '@iconify/icons-mdi/camera';

import check from '@iconify/icons-mdi/check';
import checkcircle from '@iconify/icons-mdi/check-circle';
import close from '@iconify/icons-mdi/close';

import accountplus from '@iconify/icons-mdi/account-add';
import accountcheck from '@iconify/icons-mdi/account-check';
import accountcancel from '@iconify/icons-mdi/account-cancel';
import accountclock from '@iconify/icons-mdi/account-clock';
import accountminus from '@iconify/icons-mdi/account-remove';

import card from '@iconify/icons-mdi/card-account-details';
import cardstar from '@iconify/icons-mdi/card-account-details-star';
import cardscan from '@iconify/icons-mdi/credit-card-scan';
import cardscanoutline from '@iconify/icons-mdi/credit-card-scan-outline';
import cardclock from '@iconify/icons-mdi/credit-card-clock';
import cardrefresh from '@iconify/icons-mdi/credit-card-refresh';
import cardcheck from '@iconify/icons-mdi/credit-card-check';
import cardremove from '@iconify/icons-mdi/credit-card-remove';
import cardoff from '@iconify/icons-mdi/credit-card-off';
import cardrefund from '@iconify/icons-mdi/credit-card-refund';
import star from '@iconify/icons-mdi/star';
import textclock from '@iconify/icons-mdi/clipboard-clock-outline';

import alert from '@iconify/icons-mdi/alert';
import calendarcheck from '@iconify/icons-mdi/calendar-check';

import userentrance from '@iconify/icons-mdi/account-arrow-left-outline';
import usersubscribe from '@iconify/icons-mdi/account-plus-outline';
import userassigned from '@iconify/icons-mdi/smart-card-outline';
import userunassigned from '@iconify/icons-mdi/card-off-outline';
import userrenew from '@iconify/icons-mdi/refresh';
import cardnew from '@iconify/icons-mdi/credit-card-plus-outline';
import cardlost from '@iconify/icons-mdi/credit-card-marker-outline';
import cardreturned from '@iconify/icons-mdi/credit-card-refund-outline';
import cardanomaly from '@iconify/icons-mdi/credit-card-remove-outline';
import userdisabled from '@iconify/icons-mdi/account-remove-outline';
import userenabled from '@iconify/icons-mdi/account-check-outline';
import usercircle from '@iconify/icons-mdi/account-circle-outline';

import { MembersEditComponent } from './views/members/members-edit/members-edit.component';
import { DatePipe } from '@angular/common';
import { IconService } from '@visurel/iconify-angular';
import { FormBuilder } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';

export const appIcons = {
  camera,
  cameraplus,
  cameraoff,
  cameraretake,
  check,
  checkcircle,
  close,
  accountplus,
  accountcheck,
  accountcancel,
  accountclock,
  accountminus,
  card,
  cardstar,
  cardscan,
  cardscanoutline,
  cardclock,
  cardrefresh,
  cardremove,
  cardoff,
  cardrefund,
  star,
  textclock,
  userentrance,
  usersubscribe,
  userassigned,
  userunassigned,
  userrenew,
  cardnew,
  cardlost,
  cardreturned,
  cardanomaly,
  userdisabled,
  userenabled,
  alert,
  usercircle,
  cardcheck,
  calendarcheck,
};

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
  providers: [IconSetService, DatePipe],
})
export class AppComponent implements OnInit {
  public toasterconfig: ToasterConfig = new ToasterConfig({
    tapToDismiss: true,
    timeout: { success: 2000 },
    animation: 'fade',
    showCloseButton: { warning: true, error: false, success: false },
    limit: 1,
  });

  public progress: any = {};

  public attemps = 0;
  public displayClose = true;

  public message: string = null;

  constructor(
    private readonly router: Router,
    private readonly iconSet: IconSetService,
    private readonly toasterService: ToasterService,
    private readonly websocketService: WebsocketService,
    private readonly apiService: ApiService,
    private readonly activeRoute: ActivatedRoute,
  ) {
    iconSet.icons = { cilEnvelopeOpen, ...flagSet };
  }

  ngOnInit(): void {
    this.websocketService.currentMessage.subscribe((message) => (this.message = message));
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.initWebSocket(this.isParam('reinit'));
  }

  newMessage(message) {
    this.websocketService.changeMessage(message);
  }

  public isParam(param: string): boolean {
    const queryParams = this.activeRoute.snapshot.queryParams;
    return queryParams[param] ? queryParams[param] : false;
  }

  public initWebSocket(reinit?: boolean): void {
    this.websocketService.listenToTheSocket().subscribe(
      (result: NfcMessage) => {
        console.log('RESULT', result);
        switch (result.type) {
          case 'open':
            this.webSocketOpen(reinit, result);
            break;
          case 'message':
            this.webSocketMessage(result);
            break;
          case 'error':
            this.webSocketError(result);
            break;
          case 'close':
            this.webSocketClose(result);
            break;
        }
      },
      (error) => {
        console.log('listenToTheSocket error', error);
      },
    );
  }

  public displayToast(type, title, content): void {
    const toast = this.toasterService.pop(type, title, content);
    setTimeout(() => {
      this.toasterService.clear(toast.toastId, toast.toastContainerId);
    }, 3000);
  }

  public webSocketOpen(reinit, result): void {
    this.attemps = 0;
    this.displayClose = true;
    if (!reinit) {
      this.displayToast('success', 'Succes', result.data);
    }
  }

  public webSocketMessage(result): void {
    const queryParams = this.activeRoute.snapshot.queryParams;
    if (queryParams.formChanged === 'false' || !queryParams.formChanged) {
      const data = JSON.parse(result.data);
      if (data?.tag) {
        if (
          !this.websocketService.newCardModalOpen &&
          !this.websocketService.newMemberCardModalOpen
        ) {
          this.apiService.getCard(data.tag).subscribe(
            (resCard: any) => {
              if (resCard?.user?.id) {
                this.displayToast(
                  'success',
                  'Succès',
                  `L'utilisateur associé à la carte a été trouvé.`,
                );
                this.router.navigate(['/']).then(() => {
                  this.router
                    .navigate(['adherents/edition/' + resCard.user.id], {
                      queryParams: { reinit: true },
                    });
                });
              } else {
                this.displayToast(
                  'error',
                  'Erreur',
                  `L'utilisateur associé à la carte n'a pas été trouvé.`,
                );
              }
            },
            () => {
              this.displayToast('error', 'Erreur', `La carte n'a pas été trouvé.`);
            },
          );
        }
        if (
          this.websocketService.newCardModalOpen &&
          !this.websocketService.newMemberCardModalOpen
        ) {
          this.websocketService.actionUserCard('new-card', data.tag).subscribe(
            () => {
              this.displayToast('success', 'Succès', this.websocketService.message);
              this.newMessage('get');
            },
            (err: any) => {
              if (err?.error?.message) {
                this.displayToast('error', 'Erreur', err.error.message);
                this.newMessage('modal');
              }
            },
          );
        }
        if (
          !this.websocketService.newCardModalOpen &&
          this.websocketService.newMemberCardModalOpen
        ) {
          this.websocketService.actionUserMemberCard('new-member-card', data.tag).subscribe(
            () => {
              this.displayToast('success', 'Succès', this.websocketService.message);
              this.newMessage('get');
            },
            (err: any) => {
              if (err?.error?.message) {
                this.displayToast('error', 'Erreur', err.error.message);
                this.newMessage('modal');
              }
            },
          );
        }
      }
    }
    if (queryParams.formChanged === 'true') {
      this.displayToast('warning', 'Attention', `Changements non enregistrés.`);
    }
  }

  public webSocketError(result): void {
    if (this.attemps < 1) {
      const error = result.error ? result.error : null;
      this.displayToast('error', 'Erreur', error);
      this.displayClose = false;
    }
    setTimeout(() => {
      this.attemps++;
      this.websocketService.closeSocket();
    }, 5000);
  }

  public webSocketClose(result): void {
    if (!this.isParam('setcard')) {
      if (this.attemps < 10) {
        if (this.displayClose) {
          const msg = result.msg ? result.msg : null;
          this.displayToast('error', 'Erreur', msg);
          setTimeout(() => {
            this.websocketService.closeSocket();
            this.initWebSocket(false);
          }, 5000);
        } else {
          setTimeout(() => {
            this.initWebSocket(false);
          }, 5000);
        }
      } else {
        this.attemps = 0;
      }
    }
  }
}
