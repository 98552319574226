
interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: MyINavData[] = [
  {
    name: 'Adhérents',
    url: '/adherents',
    icon: 'icon-people',
    roles: [],
  },
  {
    name: 'Membres',
    url: '/membres',
    icon: 'icon-star',
    roles: [],
  },
  {
    name: 'Statistiques',
    url: '/statistiques',
    icon: 'icon-graph',
    roles: [],
  },
  {
    name: 'Caisse',
    url: '/caisse',
    icon: 'icon-wallet',
    roles: [],
  }
];

export interface MyINavData extends NavData {
  roles: string[];
  permissions?: string[];
}