import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '../../shared/models/page.model';

@Injectable()
export class HttpGenericService {
  private api = 'api/';

  constructor(private _http: HttpClient) {}

  /*************************************** GET ***************************************/

  /**
   * Service générique GET
   *
   * @param {any} ressource Nom de la ressource backend
   * @param {string} [id=""] Id de l'ogjet ou fin de l'url
   * @returns {Observable<any>}
   * @memberof GenericService
   */
  get<T>(ressource: string, id: string = ''): Observable<T> {
    const postUrl = id !== '' ? `/${id}` : '';
    return this._http.get<T>(`${this.api}${ressource}${postUrl}`);
  }

  /**
   * Service générique GET Async
   *
   * @param {string} ressource : nom de la ressource backend
   * @param {string} id: id de l'objet
   * @returns {Promise<any>}
   * @memberof HttpGenericService
   */
  async getAsync<T>(ressource: string, id: string = ''): Promise<T> {
    return this.get<T>(ressource, id).toPromise();
  }

  /*************************************** SEARCH ***************************************/
  /**
   * Service générique de recherche
   * @param {string} ressource Nom de la ressource backend
   * @param {object} [params=null] Parametres supplementaires
   * @param {Page} [page=null] pour la pagination
   * @param {boolean} [search=true] permet d'ajouter _search à la fin de l'url
   * @returns Observable<any>
   * @memberof GenericService
   */
  search<T>(
    ressource: string,
    params: object = null,
    page: Page = null,
    search: boolean = true
  ): Observable<T> {
    return this._http.post<T>(`${this.api}${ressource}/_search`, {
      ...params,
      ...{ page: page },
    });
  }

  /**
   * Service générique POST SEARCH Async
   *
   * @param {string} ressource : nom de la ressource backend
   * @param {object} [params=null] Parametres supplementaires
   * @param {Page} [page=null] pour la pagination
   * @param {boolean} [search=true] permet d'ajouter _search à la fin de l'url
   * @returns {Promise<any>}
   * @memberof HttpGenericService
   */
  async searchAsync<T>(
    ressource: string,
    params: object = null,
    page: Page = null,
    search: boolean = true
  ): Promise<T> {
    return this.search<T>(ressource, params, page, search).toPromise();
  }

  /*************************************** SAVE: PUT && POST GENERIC ***************************************/
  /**
   * Update ou create une ressource
   * @param ressource
   * @param obj
   */
  save<T>(ressource: string, obj: any): Observable<T> {
    let request: Observable<any>;
    if (obj && obj.id) {
      request = this.update(ressource, obj);
    } else {
      request = this.create(ressource, obj);
    }
    return request;
  }

  /*************************************** PUT ***************************************/
  /**
   * update générique
   * @param ressource ressource de l'api
   * @param obj objet à modifier
   */
  put<T>(ressource: string, obj: any, api: string = this.api): Observable<T> {
    return this._http.put<T>(`${api}${ressource}`, obj);
  }

  /*************************************** POST ***************************************/
  /**
   * Service générique POST
   *
   * @param {any} ressource Nom de la ressource backend
   * @returns {Observable<any>}
   * @memberof HttpGenericService
   */
  post<T>(ressource: string, obj: any, api: string = this.api): Observable<T> {
    return this._http.post<T>(`${api}${ressource}`, obj);
  }

  /*************************************** CREATE ***************************************/
  /**
   * Service générique CREATE
   * @param ressource
   * @param obj
   */
  create(ressource: string, obj: any, api: string = this.api): Observable<any> {
    return this._http.post(`${api}${ressource}/save`, obj);
  }

  /*************************************** UPDATE ***************************************/
  /**
   * Service générique update
   * @param ressource
   * @param obj
   */
  update(ressource: string, obj: any, api: string = this.api): Observable<any> {
    return this._http.put(`${api}${ressource}/${obj.id}`, obj);
  }

  /*************************************** DELETE ***************************************/
  /**
   * Delete generique
   * @param ressource ressource de l'api
   * @param obj : soit l'objet à delete soit des parametres
   * @param api : api utilisé, default : 'api'
   */
  delete<T>(
    ressource: string,
    obj: any = null,
    api: string = this.api
  ): Observable<T> {
    let req: Observable<T>;
    if (obj && obj.id) {
      req = this._http.delete<T>(`${api}${ressource}/${obj.id}`);
    } else {
      req = this._http.request<T>('delete', `${api}${ressource}`, {
        body: obj,
      });
    }
    return req;
  }
}
