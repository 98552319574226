import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthResolve implements Resolve<string[]> {
  constructor(private authService: AuthService) {}

  resolve(): Observable<string[]> {
    /// return of(['granted']);
    return this.authService.getRights().pipe(
      catchError((_) => {
        return of(null);
      })
    );
  }
}
