
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { ApiService } from './api.service';

const destinations = [
  'wss://localhost:8082/ws',
  'ws://localhost:8081/ws',
  'wss://10.0.0.1/ws',
  'ws://10.0.0.1/ws',
];

export interface NfcMessage extends Event {
  error?: string;
  msg?: string;
  data?: any;
}

@Injectable()
export class WebsocketService {
  public tag: any = null;
  private ws: WebSocket;

  public user: User = null;
  public message: string = null;
  public newCardModalOpen = false;
  public newMemberCardModalOpen = false;

  private readonly messageSource = new BehaviorSubject<string>('default message');
  currentMessage = this.messageSource.asObservable();

  private nextDestination: number = 0;

  constructor(
    private readonly apiService: ApiService,
  ) {
  }

  public changeMessage(message: string) {
    this.messageSource.next(message);
  }

  public closeSocket() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }

  public listenToTheSocket(): Observable<NfcMessage> {
    console.debug(`websocket nextDestination=${this.nextDestination} ${destinations[this.nextDestination]}`);
    if (!this.ws) {
      const url = destinations[this.nextDestination];
      this.nextDestination = (this.nextDestination + 1) % destinations.length;

      try {
        this.ws = new WebSocket(url);
      } catch (e) {
        console.error("websocket creation failed", e);
        return new Observable<NfcMessage>((obs) => {
          setTimeout(() => {
            const evt = {
              type: 'error',
              error: e,
            } as NfcMessage;
            obs.next(evt);
            setTimeout(() => {
              const evt = {
                type: 'close',
                msg: `Connexion avec le lecteur de carte échouée.`
              } as NfcMessage;
              obs.next(evt);
            }, 100);
          }, 100);
        });
      }
    }
    return new Observable<NfcMessage>((observer) => {
      this.ws.addEventListener('message', (e: MessageEvent) => {
        const evt: NfcMessage = e;
        console.log('websocket message', evt);
        observer.next(evt);
      });
      this.ws.addEventListener('error', (e: Event) => {
        console.log('websocket error', e);
        const evt: NfcMessage = e;
        evt.error = `Connexion avec le lecteur de carte échouée.`;
        observer.next(evt);
      });
      this.ws.addEventListener('close', (e: CloseEvent) => {
        console.log('websocket close', e);
        const evt: NfcMessage = e;
        evt.msg = `Connexion avec le lecteur de carte échouée.`;
        observer.next(evt);
      });
      this.ws.addEventListener('open', (e: Event) => {
        console.log('websocket open', e);
        const evt: NfcMessage = e;
        evt.data = `Connexion avec le lecteur de carte établie.`;
        observer.next(evt);
      });
    });
  }

  public actionUserCard(action: string, cardId: string): Observable<any> {
    const request = this.apiService.actionsUser(action, this.user.id, cardId);
    this.message = `La carte a bien été associée à l'adhérent.`;
    return request;
  }

  public actionUserMemberCard(action: string, cardId: string): Observable<any> {
    const request = this.apiService.actionsUser(action, this.user.id, cardId);
    this.message = `La carte membre a bien été associée à l'adhérent.`;
    return request;
  }

  getSocketStatus(): number {
    return this.ws?.readyState ?? 0;
  }
}
