import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'adherents',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Connexion' },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: { title: 'Fronton' },
    children: [
      {
        path: 'adherents',
        loadChildren: () => import('./views/members/members.module').then((m) => m.MembersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'membres',
        loadChildren: () => import('./views/subscribers/subscribers.module').then((m) => m.SubscribersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'statistiques',
        loadChildren: () => import('./views/statistics/statistics.module').then((m) => m.StatisticsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'caisse',
        loadChildren: () => import('./views/funds/funds.module').then((m) => m.FundsModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
