import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { LocationStrategy, registerLocaleData, PathLocationStrategy, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from './shared/shared.module';
import { LaddaModule } from 'angular2-ladda';

import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import localeFr from '@angular/common/locales/fr';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import 'moment/locale/fr';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './core/services/global-error-handler';
import { TokenInterceptor } from './core/services/token.interceptor';
import { HttpGenericService } from './core/services/http.generic.service';

defineLocale('fr', frLocale);
registerLocaleData(localeFr, 'fr-FR');
moment.locale('fr');

import { WebsocketService } from './shared/services/websocket.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { keycloakInitializer } from './shared/utils/keycloak-initializer';
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthService } from './shared/services/auth.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    SharedModule,
    CoreModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ToasterModule.forRoot(),
    LaddaModule,
    IconSetModule.forRoot(),
    KeycloakAngularModule
  ],
  exports: [AppComponent],

  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
  ],
  providers: [
    HttpGenericService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ToasterService,
    IconSetService,
    WebsocketService,
    {
      useFactory: keycloakInitializer,
      provide: APP_INITIALIZER,
      deps: [KeycloakService],
      multi: true,
    },
    AuthGuard,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
