import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    this.authService.redirectUrl = url !== '/auth/locked' ? url : '/admin';
    if (this.authService.isAuthenticated()) {
      return true;
    }
    // Store the attempted URL for redirecting
    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }
}
