<app-header [navbarBrandRouterLink]="['/']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/fronton.png', width: 112, height: 38, alt: 'Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/AMICALE_BASQUE.png', width: 30, height: 30, alt: 'Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="false">

  <ul class="nav navbar-nav ml-auto">
    <!-- <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profil </a>
        <a class="dropdown-item" href="#"><i class="fa fa-lock"></i> Déconnexion </a>
      </div>
    </li> -->
    <li class="nav-item">
        <button *ngIf="isLoggedIn" class="btn btn-dark mr-3" type="button" (click)="logout()"><i class="icon-logout mr-1"></i> Déconnexion</button>
    </li>
  </ul>

</app-header>

<div class="app-body">

  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <h3 [ngClass]="deviceConnected != 1 ? 'text-danger' : 'text-success'" class="ml-3"><i class="fa fa-usb"></i></h3>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>

  <main class="main">
    <cui-breadcrumb></cui-breadcrumb>
    <div class="container-fluid"> <router-outlet></router-outlet> </div>
  </main>

</div>

<toaster-container id="toaster-container" [toasterconfig]="toasterconfig"></toaster-container>

<!-- <app-footer></app-footer> -->
