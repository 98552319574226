import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthResolve } from './authentification/auth.resolve';
import { AuthGuardService } from './authentification/guards/auth.guard';
import { CacheService } from './services/cache.service';
import { GlobalErrorHandler } from './services/global-error-handler';
import { SweetAlertService } from './services/sweetalert2.service';
import { UserService } from './services/user.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module,
  ],
  providers: [
    TokenInterceptor,
    GlobalErrorHandler,
    AuthResolve,
    AuthGuardService,
    CacheService,
    SweetAlertService,
    UserService,
  ],
  exports: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
