import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';

@Injectable()
export class AuthService {
    constructor(private keycloakService: KeycloakService) { }

    public getLoggedUser(): KeycloakTokenParsed | undefined {
        try {
            const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance()
                .idTokenParsed;
            return userDetails;
        } catch (e) {
            console.error("Exception", e);
            return undefined;
        }
    }

    public isLoggedIn(): Promise<boolean> {
        return this.keycloakService.isLoggedIn();
    }

    public loadUserProfile(): Promise<KeycloakProfile> {
        return this.keycloakService.loadUserProfile();
    }

    public login(): void {
        this.keycloakService.login();
    }

    public logout(): void {
        this.keycloakService.logout();
    }

    public isUserInRoleAdmin(): boolean {
        return this.keycloakService.isUserInRole("admin");
    }
}
