import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, throwError } from 'rxjs';
import { User } from '../../shared/models/user.model';
import { Subject, Observable, of } from 'rxjs';
import { SweetAlertService } from '../services/sweetalert2.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public isLoggedIn = false;
  public permissions: string[];

  public isloggedInSub: Subject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn);
  public loggInSub: Subject<boolean> = new BehaviorSubject<boolean>(null);
  // store the URL so we can redirect after logging in
  redirectUrl: string;
  private readonly helper: JwtHelperService;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private swalSvc: SweetAlertService,
  ) {
    this.helper = new JwtHelperService();
  }

  getUser(): User {
    const user = localStorage.getItem('user');
    if (user) {
      return new User(JSON.parse(user));
    } else {
      return null;
    }
  }

  isLogged(): boolean {
    return this.isLoggedIn;
  }
  /**
   * Retourne la route par défaut selon les permissions du user
   */
  getUserDefaultRoute(): string {
    const defaultRoute = '/admin';
    return defaultRoute;
  }

  /**
   * Retourne les permissions, soit en cache soit un GET
   */
  getRights(): Observable<string[]> {
    let result: Observable<string[]>;

    if (this.permissions) {
      result = of(this.permissions);
    } else {
      result = this.httpClient.get('permissions?granted=true').pipe(
        map((permissions: { permissions: string[] }) => {
          this.permissions = permissions ? permissions.permissions : [];
          return this.permissions;
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage =
            error.status === 502 || error.status === 503 ? 'reboot' : 'permissions';

          this.swalSvc.error({ text: `Erreur lors de la lecture des permissions` });

          this.router.navigate(['/login', { error: errorMessage }]);
          return throwError(error);
        }),
      );
    }
    return result;
  }
  getToken() {
    return localStorage.getItem('token');
  }

  private setToken(token: string) {
    localStorage.setItem('token', token);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    const isExpired = this.helper.isTokenExpired(token);

    if (token !== null && !this.isLoggedIn && !isExpired) {
      // log from storage on f5 or app reload
      this.isLoggedIn = true;
      this.isloggedInSub.next(this.isLoggedIn);
    }

    return token !== null && !isExpired;
  }

  hasRole(expectedRoles: Array<string>) {
    const user = this.getUser();
    let isAllowed = false;

    expectedRoles.forEach((role) => {
      if (user.userProfile === role) {
        isAllowed = true;
      }
    });
    return isAllowed;
  }

  hasPermissions(neededPermissions: string[]): boolean {
    let isAllowed = true;

    if (this.permissions && neededPermissions) {
      neededPermissions.forEach((permission) => {
        const isIn: boolean = this.permissions.includes(permission);
        isAllowed = isAllowed && isIn;
      });
    } else if (!this.permissions) {
      isAllowed = false;
    }
    return isAllowed;
  }

  /**
   *  EXEMPLE
   * @param username
   * @param password
   * @returns
   */
  private buildBody(username, password) {
    const data = {
      auth: {
        identity: {
          methods: ['password'],
          password: {
            user: {
              domain: {
                name: 'Default',
              },
              name: username,
              password: password,
            },
          },
        },
      },
    };
    return data;
  }

  /**
   * EXEMPLE
   * @param username
   * @param password
   * @param redirect
   * @returns
   */
  login(username: string, password: string, redirect = true): Promise<any> {
    const body = this.buildBody(username, password);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return new Promise((resolve, reject) => {
      this.httpClient
        .post('/loginV3', JSON.stringify(body), {
          headers,
          withCredentials: true,
          observe: 'response',
        })
        .subscribe(
          (response: any) => {
            this.parseAuthResponse(response);
            this.isLoggedIn = true;
            this.isloggedInSub.next(this.isLoggedIn);
            if (redirect) {
              this.router.navigate(this.redirectUrl ? [this.redirectUrl] : ['/admin']);
            }
            return resolve(response);
          },
          (response) => {
            console.error('loginV3 error', response);
            this.isLoggedIn = false;
            this.loggInSub.next(this.isLoggedIn);
            return reject(response);
          },
        );
    });
  }

  /**
   * EXEMPLE
   * @param response
   */
  private parseAuthResponse(response): void {
    const token = response.headers.get('X-Subject-Token') || response.headers.get('X-Auth-Token');
    this.setToken(token);
  }

  private handleError(error: Response) {
    console.error('handleError ', error);
    // return Observable.throw(error || 'Server Error');
  }

  public deleteLoginData() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  logout(): void {
    this.deleteLoginData();
    this.isLoggedIn = false;
    this.isloggedInSub.next(this.isLoggedIn);
  }

  lock(): void {
    localStorage.removeItem('token');
    this.isLoggedIn = false;
    this.isloggedInSub.next(this.isLoggedIn);
  }
}
