import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { of, Subject, Observable } from 'rxjs';
import { User, UserInfo, UsernameError } from '../../shared/models/user.model';

@Injectable()
export class UserService {
  public user: Subject<any> = new Subject();
  public userInfo: any;

  constructor(private _http: HttpClient) {}

  getLoginInfo(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.setUserInfo(user, false);
      return of(user);
    }
    return this._http
      .get('/api/env')
      .pipe(tap((data: any) => this.setUserInfo(data.user)));
  }

  // getUserInfo(): UserInfo {
  //   return this.userInfo;
  // }

  setUserInfo(user: UserInfo, save = true): void {
    if (save) {
      localStorage.setItem('user', JSON.stringify(user));
    }
    this.userInfo = user;
    this.user.next(user);
  }

  // getAuthenticationData(username: string): Observable<UserInfo> {
  //   return this._http
  //     .get<UserInfo>(`api/user/getAuthenticationData/${username}`)
  //     .pipe(tap((user: UserInfo) => this.setUserInfo(user)));
  // }

  // changePassword(parameters): Observable<any> {
  //   return this._http.post('api/user/changePassword', parameters);
  // }

  // unlockAccountAndResetPassword(parameters): Observable<any> {
  //   return this._http.post(
  //     'api/user/unlockAccountAndResetPassword',
  //     parameters
  //   );
  // }

  getById(id: string): Observable<User> {
    return this._http.get<User>('api/users/' + id);
  }

  getUsernameError(data: UserInfo): UsernameError {
    let msg = '';
    let redirect = null;
    let hasError = false;

    if (data.isRadie) {
      msg = 'Ce compte est désactivé';
      redirect = null;
      hasError = true;
    } else if (data.isLockedOut) {
      msg = 'Ce compte est verrouillé. Merci de contacter un administrateur';
      redirect = null;
      hasError = true;
    } else if (data.valid) {
      // redirect = '/login/password';
      msg = '';
      redirect = null;
      hasError = false;
    } else if (data.userId === null) {
      msg = `Ce compte n'existe pas`;
      redirect = null;
      hasError = true;
    } else if (data.isChangePassword) {
      redirect = '/login/new-password';
      msg = 'Vous devez modifier votre mot de passe';
      hasError = true;
    }
    return { msg, redirect, hasError };
  }
}
