import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';

const acceptablePaths = [
  '/assets'
];

export function keycloakInitializer(keycloak: KeycloakService) {
  return async () => {
    keycloak.keycloakEvents$.subscribe({
      next: (e: KeycloakEvent) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          keycloak.updateToken(0).then((res) => {
            console.debug(`app: token refresh: ${res}`);
          });
        }
      },
    });

    return await keycloak.init({
      config: await (await fetch('/assets/keycloak.json')).json(),
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
        enableLogging: true,
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        flow: 'hybrid',
      },
      bearerExcludedUrls: ['/assets'],
      loadUserProfileAtStartUp: true,
    });
  };
}
