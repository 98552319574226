import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../authentification/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const oauthService = this.injector.get(AuthService);
    const token = oauthService.getToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });

      request = request.clone({ headers });
    }

    return next
      .handle(request)
      .pipe(catchError((x) => this.handleAuthError(x)));
  }

  /**
   * En cas d'erreur d'authentification (401 / 403) l'utilisateur sera déconnecté
   * En cas d'erreur de permission l'utilisateur est redirigé vers la page de login
   * @param error
   */
  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401 || error.status === 403) {
      const authService = this.injector.get(AuthService);
      authService.logout();

      if (error.url.indexOf('permissions') === -1) {
        const router = this.injector.get(Router);
        router.navigate(['/login']);
      }
    }
    return throwError(error);
  }
}
