import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToasterModule } from 'angular2-toaster';
import { MomentModule } from 'ngx-moment';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StorageService } from './services/storage.service';

import { IconModule } from '@visurel/iconify-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';

@NgModule({
  declarations: [],
  exports: [
    CommonModule,
    ToasterModule,
    BsDatepickerModule,
    MomentModule,
    ModalModule,
    BsDropdownModule,
    PaginationModule,
    IconModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    CountUpModule,
  ],
  providers: [StorageService],
  imports: [
    CountUpModule,
    NgxDatatableModule,
    IconModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    CommonModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    ToasterModule.forRoot(),
  ],
})
export class SharedModule {}
