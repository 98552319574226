/* *************************************************** */

import { Page } from './page.model';

export type PaginatedUsers = Page & {
  users: User[];
};

export class User {
  birthdate: Date | string;
  cards: Card[];
  certified: true;
  comment: string;
  created_at: string;
  deleted: string;
  enable: true;
  firstname: string;
  history: History[];
  id: string;
  is_member: boolean;
  lastname: string;
  mail: string;
  phone: string;
  photo_id: string;
  updated_at: string;
  valid_until: string;
  vehicle_registration: string;
  fLastname?: string;
  expired?: boolean;
  userCard?: Card;
  userMemberCard?: Card;

  valid?: boolean;
  userId?: string;
  userLogin?: string;
  userName?: string;
  name?: string;
  userProfile?: string;
  isLockedOut?: boolean;
  isChangePassword?: boolean;
  shortName?: string;
  isRadie?: boolean;
  constructor(obj?) {
    Object.assign(this, obj);
  }
}

/* *************************************************** */

export class Card {
  created_at: string | Date;
  history: History[];
  id: string;
  updated_at: string | Date;
  user_id: string;
  user: User;
  card_type: string;
}

export class History {
  card_id: string;
  comment: string;
  created_at: string | Date;
  id: string;
  operation: string;
  user_id: string;
}

export class Count {
  count: number;
  time: string;
}

/* *************************************************** */

export interface UsernameError {
  msg: string;
  redirect: string;
  hasError: boolean;
}

export interface UserInfo {
  valid: boolean;
  userId: string;
  userLogin: string;
  userName: string;
  userProfile: string;
  isLockedOut: boolean;
  isChangePassword: boolean;
  isRadie: boolean;
}

/* *************************************************** */
