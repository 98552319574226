import { Component, OnDestroy, OnInit } from '@angular/core';
import { Toast, ToasterConfig, ToasterService } from 'angular2-toaster';
import { SweetAlertService } from '../../core/services/sweetalert2.service';
import { navItems } from '../../_nav';
import { WebsocketService } from '../../shared/services/websocket.service';
import { Subscription, interval } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css'],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy{
  
  public sidebarMinimized = false;

  public navItems = navItems;

  public deviceConnected = 3;
  sub: Subscription;

  public toasterconfig: ToasterConfig = new ToasterConfig({
    tapToDismiss: true,
    timeout: 0,
    positionClass: 'toast-top-right',
    animation: 'fade',
    showCloseButton: { warning: true, error: true, success: false },
  });

  public isLoggedIn: boolean = false;

  constructor(
    private toasterService: ToasterService, 
    private swalSvc: SweetAlertService,
    private readonly websocketService: WebsocketService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.sub = interval(2000).subscribe(() => {
      this.deviceConnected = this.websocketService.getSocketStatus();
    });

    this.isLoggedIn = await this.authService.isLoggedIn();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  openToast(e: Event) {
    e.preventDefault();
    const toast: Toast = {
      type: 'success',
      title: 'title',
      body: 'Exemple de toaster',
      timeout: 0,
      showCloseButton: true,
    };
    this.toasterService.pop(toast);
  }

  openNotif(e: Event) {
    e.preventDefault();
    const options = {
      showCancelButton: true,
      allowOutsideClick: false,
      type: 'question',
      text: 'Imprimer les bordereaux de remise en banque ?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Télécharger le PDF',
    };
    this.swalSvc.confirm(options).then((response) => {
      console.log('response', response);
      if (response.value) { }
    }).catch(() => console.log('canceled'));
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout(): void {
    this.authService.logout();
  }

}
